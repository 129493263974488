@use "@angular/material" as mat;
@include mat.core();

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@import "./variables.scss";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

$my-primary: mat.m2-define-palette($ftnss-pallette, 500, 50, 300);
$my-accent: mat.m2-define-palette($ftnss-pallette, A200, A100, A400);

$custom-typography: mat.m2-define-typography-config(
  $font-family: "Inconsolata",
);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: $custom-typography,
  )
);
@include mat.all-component-themes($my-theme);

.dark {
  @include dark;
}

.light {
  @include light;
}

/*
 *******************
       F O N T
 *******************
*/

input,
button {
  border: none;
}

.poi {
  cursor: pointer !important;
}

.fi::before {
  line-height: 1.3 !important;
}

/*
 *******************
    G E N E R A L
 *******************
*/

*,
*:focus,
*:active {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Inconsolata", sans-serif !important;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
  &.dark {
    background-color: $colb3 !important;
  }
  &.light {
    background-color: $colwb !important;
  }
}

.fmy {
  font-family: "Inconsolata", sans-serif !important;
}

.ftnss-box {
  width: 95vw;
  max-width: 78rem;
}

.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.fabg {
  background: linear-gradient(120deg, #5176c5, #3b5998);
}

.twibg {
  background: linear-gradient(60deg, #00acee, #0188be);
}

.watbg {
  background: linear-gradient(60deg, #1ebea5, #00e676);
}

.spacer {
  margin-top: 20px;
  min-height: 200px;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
}

.tabler {
  width: 100%;
}

.onepage {
  min-height: 100vh;
  width: 100%;
  padding-top: 5%;
}

.testcontent {
  width: 100%;
  height: 400px;
  background-color: orange;
  display: inline-flex;
  align-items: center;
  h1 {
    text-align: center;
    width: 100%;
  }
}

.floader {
  img {
    animation: spin 3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.cimg {
  object-fit: cover;
}

.mimg {
  object-fit: cover;
  aspect-ratio: 16/11;
}

.gimg {
  aspect-ratio: 21/11;
}

.mat-snack-bar-container {
  width: 90vw !important;
  max-width: 300px !important;
  border-radius: $brad2 !important;
  span {
    font-weight: 500 !important;
  }
  button {
    border-radius: $brad2 !important;
  }
}

.container {
  max-width: 960px;
  margin-top: 10px;
}

.mason {
  padding: 0 !important;
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  background-color: #333;
  font-size: 1em;
  text-align: center;
  border-radius: 7px;
  display: inline-flex;
  padding: 7px;
  border: none;
  outline: none;
  z-index: 99;
}
.topp {
  right: 5px;
}
.footerShow {
  left: 5px;
}

.conimg {
  filter: contrast(0.3);
}

/*
 *******************
    H E A D E R S
 *******************
*/

.h22 {
  text-align: center;
  margin: 0 auto;
  width: 80%;
  max-width: 400px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 300;
  padding: 10px;
  border-radius: 5px 5px 50px 50px;
}

.conbox {
  display: inline-block;
  position: relative;
  width: 100%;
  .conright {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 50px;
    font-size: 18px;
    padding-left: 16px;
  }
  .conleft {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 50px;
    font-size: 18px;
    padding-left: 16px;
  }
}

.conlist {
  padding-top: 0.8rem;
  padding-right: 50px;
  height: 6.3rem;
  overflow: auto;
  display: flex;
  align-content: flex-end;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.conlist::-webkit-scrollbar {
  display: none !important;
}

.dpk {
  min-width: 4rem;
  padding: 0.3rem;
}

/*
 **********************************
      B U T T O N S  &  L I N K S
 ***********************************
*/

.fbtn {
  display: inline-flex;
  margin: 5px auto !important;
  padding: 10px 20px;
  border-radius: 30px !important;
  background-image: linear-gradient(to right bottom, #444, #222);
  mat-icon {
    margin: 0 3px;
  }
}

.fbtn:hover {
  background-image: linear-gradient(to right bottom, #222, #111);
}

.fbtn:disabled {
  color: #55555577 !important;
  background-image: linear-gradient(to right bottom, #77777777, #66666677);
}

.fbtn2 {
  color: #333;
  border: 3px solid #444 !important;
  display: block;
}

.ftnssmenu {
  cursor: pointer;
  border-radius: 15px;
  margin: 5px;
  font-size: 1rem;
  padding: 5px 7px;

  a {
    padding-left: 15px !important;
  }
}

.nav-item {
  border-radius: 30px;
  padding: 1px 7px;
}

// * FORMS

form {
  text-align: center;
  select,
  input,
  textarea {
    display: block;
    margin: 10px auto;
    width: 95%;
    background-color: #333;
    border-radius: 30px;
    outline: none;
    appearance: none;
    padding: 7px;
    padding-left: 12px;
    font-size: 0.8em;
  }
  option {
    font-family: "Inconsolata", sans-serif !important;
  }
  button {
    display: block;
  }
  .split {
    width: 29% !important;
    margin: 5px;
    display: inline-block;
  }
}

.cdk-overlay-container {
  z-index: 10013999999 !important;
}

.pac-container {
  z-index: 9999 !important;
}

.mat-form-field {
  display: block !important;
  margin: 5px auto;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 15px 20px 2px 20px !important;
}
.mat-form-field-underline {
  display: none !important;
}

.mat-select-panel {
  border-radius: $brad !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border: none !important;
}

.mat-tab-label {
  border-radius: $brad2;
  margin: 2px 10px;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.mat-ink-bar {
  display: none;
}

// * FTNSS-CARD

.timepicker-overlay,
.timepicker-backdrop-overlay {
  z-index: 99999 !important;
}

.timepicker-button > *,
.timepicker-dial > *,
.clock-face > * {
  font-family: "Inconsolata" !important;
}

.scol {
  padding: 5px !important;
}

.card {
  background-color: transparent;
  border: none;
  display: inline-flex;
  padding: 5px;
}

// * DIALOG-BOX

.dlogx {
  position: absolute;
  top: 7px;
  right: 10px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #ff5a48;
  border-radius: 50%;
  font-size: 1.5em;
  line-height: 0;
}

.mat-dialog-container {
  border-radius: 30px !important;
  position: relative;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.maindlog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.nopad {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.faqdlog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.transpad {
  max-width: 100vw !important;
  .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.transbdrop {
  background-color: #00000077;
  backdrop-filter: blur(4px);
}

.termsbd {
  background-color: #000000dd;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
  .mat-expansion-panel-header:hover {
  background: transparent !important;
}

.rBor {
  border-radius: 0 20px 20px 0;
}

.pBox {
  position: relative;
  border-width: 10px !important;
  .pname {
    height: 3.5em;
    font-size: 18px;
  }
  .gimg {
    aspect-ratio: 4/3;
    object-fit: cover;
  }
  .uimg {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .days {
    position: absolute;
    top: 0px;
    right: 65px;
    border-radius: 6px;
    width: 30%;
    font-size: 1.5rem;
  }
  .qr {
    position: absolute;
    top: 0px;
    right: 15px;
  }
  .exp {
    position: absolute;
    top: 14px;
    left: -5%;
    font-size: 10px;
  }
  .title {
    position: absolute;
    top: 0;
    left: -5%;
    font-size: 8px;
  }
  .transfer {
    position: absolute;
    right: 15px;
    bottom: 5px;
    font-size: 14px;
    width: 80px;
  }
  .sell {
    position: absolute;
    right: 100px;
    bottom: 5px;
    font-size: 14px;
    width: 80px;
  }
}

// * AUTOFILL

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px $colw outset !important;
  -webkit-text-fill-color: $colb3 !important;
  // transition: background-color 5000s ease-in-out 0s;
}

@media only screen and (max-width: 1200px) {
  html,
  body {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  html,
  body {
    font-size: 16px;
  }
}

@media only screen and (max-width: 290px) {
  html,
  body {
    font-size: 12px;
  }
}

// * SLOTCARD

.psRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 5px;
  .psCol {
    width: 99%;
  }
}

.slotlist {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 5px;
  justify-items: center;
  max-width: 99vw;
  .slotcard {
    width: 99%;
    .brad {
      border-radius: 7px !important;
    }
    .image {
      width: 100% !important;
      height: 100px;
      object-fit: cover;
    }
    .pd1 {
      padding: 7px;
      margin: 0 7px;
    }
    .py1 {
      padding: 10px 5px;
    }
    .pd2 {
      padding: 1px 7px;
    }
    .mm1 {
      margin: 5px auto;
    }
    .fs7 {
      font-size: 8px;
    }
    .fs8 {
      font-size: 12px;
    }
    .fs9 {
      font-size: 14px;
    }
  }
}

.shoplist {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 5px;
  margin: auto;
  justify-items: center;
  max-width: 100vw;
  .shopcard {
    width: 99%;
    .brad {
      border-radius: 7px;
    }
    .image {
      width: 100% !important;
      height: 120px;
      object-fit: contain;
      object-position: top;
    }
    .title {
      height: 60px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .pd1 {
      padding: 7px;
      margin: 0 7px;
    }
    .py1 {
      padding: 10px 5px;
    }
    .pd2 {
      padding: 1px 7px;
    }
    .mm1 {
      margin: 5px auto;
    }
    .fs7 {
      font-size: 8px;
    }
    .fs8 {
      font-size: 12px;
    }
    .fs9 {
      font-size: 14px;
    }
  }
}

//* CHAT

.chatb {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.chatm {
  height: 50vh;
  display: table-cell;
  vertical-align: bottom;
}

.chat-right {
  width: 60%;
  float: right;
  text-align: right;
  .chas {
    border-radius: 25px 10px 10px 25px;
    display: inline-block;
  }
}

.chat-left {
  width: 60%;
  float: left;
  text-align: left;
  .chas {
    border-radius: 10px 25px 25px 10px;
    display: inline-block;
  }
}

.chaty {
  p {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  textarea {
    width: 80%;
  }
}

//* DASHBAR

.dashbar {
  position: absolute;
  overflow-y: scroll;
  top: 0;
  left: 0;
  .scrollmenu {
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .navb {
      display: inline-block;
      margin: 5px 10px;
      border-width: 7px !important;
    }
  }
  .scrollmenu::-webkit-scrollbar {
    display: none !important;
  }
}

.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .navb {
    display: inline-block;
    margin: 5px 5px;
    border-width: 7px !important;
  }
}
.scrollmenu::-webkit-scrollbar {
  display: none !important;
}

.pt-dash {
  padding-top: 60px;
}

//* FLEX

.fv-center {
  display: flex;
  align-items: center;
}

.fh-center {
  display: flex;
  justify-content: center;
}

.fz-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// * EXITER

.exiter {
  position: absolute;
  top: 6px;
  right: 10px;
  width: 28px;
  z-index: 1000;
}

.dlog-header {
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  text-align: center;
  z-index: 999;
}

// * SCROLLERS

.scroll1::-webkit-scrollbar {
  width: 5px;
  margin-top: 10px;
  border-radius: 40px;
}

.scroll1::-webkit-scrollbar-track {
  border-radius: 40px;
}

.scroll1::-webkit-scrollbar-thumb {
  border-radius: 40px;
}

ul::-webkit-scrollbar {
  width: 5px;
  margin-top: 10px;
}

ul::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

//* TOAST
.toast-container {
  z-index: 9999999999 !important;
}
.toast-container .ngx-toastr {
  box-shadow: none !important;
  border-radius: 12px !important;
}

.toast-error {
  background-color: #e30b5c !important;
  color: #fff !important;
}

.toast-warning {
  background-color: #ffdb58 !important;
  color: #222222 !important;
}
.toast-success {
  background-color: #c9cc3f !important;
  color: #222222 !important;
}
