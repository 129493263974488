:root {
  --font-family-normal: "Inconsolata";
  --swiper-theme-color: #ffffff !important;
  --swiper-navigation-size: 24px !important;
}

$ftnss-pallette: (
  50: #fce9e4,
  100: #f9d4cf,
  200: #f7b9b3,
  300: #f5a29a,
  400: #f38c7e,
  500: #fa5537,
  600: #e2785c,
  700: #c96a63,
  800: #b35f5e,
  900: #a85455,
  A100: #ffd9d1,
  A200: #ffbebe,
  A400: #ffa5a5,
  A700: #ff8d8d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$colmain: #fa5537;

$coll1: lighten($colmain, 5%);
$coll2: lighten($colmain, 15%);
$coll3: lighten($colmain, 30%);
$coll4: lighten($colmain, 35%);

$cold2: #fa5537;
$cold3: darken($cold2, 10%);
$cold4: darken($colmain, 15%);

$coltrans: #ffffff00;

$colw: #ffffff;
$colwb: #fafafa;
$colwe: #eeeeee;
$colwc: #cccccc;
$colwd: #dddddd;
$colb0: #000000;
$colbb: #171717;
$colb1: #111111;
$colb2: #222222;
$colb3: #333333;
$colb4: #444444;
$colb9: #999999;
$colred: #e30b5c;
$colgreen: #c9cc3f;
$colorange: #fa5537;
$colpurple: #755de1;
$colpurplel: #9a88e9;
$colpink: #b637fa;
$colblue: #0080ff;
$colyellow: #ffdb58;
$colgoogle: #de5246;

$colbg: #222222;

$linb: linear-gradient(to right bottom, #0c0c0c, #000, #0c0c0c);
$linb2: linear-gradient(to right bottom, #333333, #222222, #111111);
$linw: linear-gradient(to right bottom, #fcfcfc, #fff, #fcfcfc);

$lintrwr: linear-gradient(to right, transparent, #ffffffee, #ffffff);
$lintrwl: linear-gradient(to left, transparent, #ffffffee, #ffffff);

$lintrb: linear-gradient(to right, transparent, #111111ee, #111111);
$lin1: linear-gradient(to right bottom, $coll1, $colmain);
$ling: linear-gradient(
  160deg,
  darken($colmain, 30%),
  darken($colmain, 10%),
  $colmain,
  darken($colmain, 10%),
  darken($colmain, 30%)
);

@mixin dark {
  $ocolz: $colb2 bg1, $colb3 bg2, $colb3 bg3, $colb4 bg4, $colb2 bgc,
    $coltrans ctr, $colwd csw, $coll2 csw2, $colmain c0, $colmain cmain,
    $coll1 c1, $coll2 cl2, $coll3 cl3, $coll4 cl4, $cold2 cd2, $cold4 cd4,
    $colgreen ccg, #02ad02 ccg2, #02ad02 ccgb, $colpurplel ccp, $colpurplel ccp2,
    $colpurple ccp3, $colblue ccb, #e9b388 cco, $colred ccr, $colpink cck,
    #f26fb6 cckb, $colyellow ccy, $colw cw, $colwe cwe, $colwd cwd, $colb0 b0,
    $colb1 b1, $colb2 b2, $colb3 b3, $colb4 b4, $colb9 b9, $colgoogle cgg,
    $linb linbg, $linb linb, $linb2 linb2, $ling ling, $lintrb lintr, $lin1 lin1;
  @each $o in $ocolz {
    .#{nth($o, 2)},
    .#{nth($o, 2)}:hover,
    .#{nth($o, 2)}::placeholder {
      color: nth($o, 1) !important;
    }

    .bg-#{nth($o, 2)},
    .bg-#{nth($o, 2)}:focus {
      background: nth($o, 1) !important;
      background-color: nth($o, 1) !important;
    }
    .bo-#{nth($o, 2)} {
      border: 1px solid nth($o, 1);
      border-color: nth($o, 1) !important;
    }

    .bo3-#{nth($o, 2)} {
      border: 0.2em solid nth($o, 1);
      border-color: nth($o, 1) !important;
    }

    .bot-#{nth($o, 2)} {
      border-top: 0.2em solid nth($o, 1) !important;
    }

    .bol-#{nth($o, 2)} {
      border-left: 0.2em solid nth($o, 1);
    }

    .bor-#{nth($o, 2)} {
      border-right: 0.2em solid nth($o, 1) !important;
    }

    .bob-#{nth($o, 2)} {
      border-bottom: 0.2em solid nth($o, 1) !important;
    }
  }

  h2 {
    background: $linb;
  }
  input,
  select,
  textarea,
  .legal,
  .cal-cell:hover,
  section.svelte-rntogh {
    background: $colb1 !important;
  }
  h2,
  input,
  select,
  textarea,
  .legal,
  .tabsy .nav-link,
  .cal-cell:hover {
    color: $colmain !important;
  }
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number,
  .cal-month-view .cal-day-cell.cal-today {
    color: $colwd !important;
  }

  .scro::-webkit-scrollbar-thumb,
  .bn-onboard-modal-content-header-icon {
    background-color: $colmain !important;
  }

  input::placeholder,
  textarea::placeholder,
  select::placeholder {
    color: $coll2 !important;
  }

  input:focus,
  textarea:focus,
  select:focus,
  .tabsy .nav-link,
  .cal-month-view {
    background: $colb3 !important;
  }

  .bn-onboard-icon-button {
    background: $colb2 !important;
  }
  h2,
  section.svelte-rntogh {
    border: 1px solid $colmain;
  }

  .box2 {
    box-shadow: 2px 2px 4px #000000aa;
  }

  .fullpad {
    .mat-dialog-container {
      background-color: #444444 !important;
    }
  }

  .nav-item.nav-link.active,
  .progress-bar {
    background: $ling !important;
    color: $colb2 !important;
  }

  .totabs .nav-pills .nav-link {
    border-radius: 12px;
    color: $colwe;
    background-color: $colb3;
    padding: 20px 0 !important;
  }
  .totabs .nav-pills .nav-link.active {
    background: $colpink;
    color: $colb2;
  }

  .cal-day-cell,
  .cal-cell-row,
  .cal-days {
    border-color: $colb2 !important;
  }

  .cal-cell-row:hover {
    background-color: $colb2 !important;
  }

  .cal-day-selected,
  .cal-day-selected:hover {
    background-color: $colpink !important;
    color: $colw !important;
    .cal-day-number {
      color: $colw !important;
    }
  }

  .cal-month-view .cal-day-cell.cal-in-month.cal-has-events,
  .cal-day-cell.cal-in-month.cal-has-events:hover {
    background: $colpurple !important;
    color: $colw !important;
  }

  .Rmaa8 {
    background-color: #222 !important;
    color: $colmain !important;
  }

  .vscro::-webkit-scrollbar-thumb,
  .hscro::-webkit-scrollbar-thumb {
    background-color: $colmain;
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem #00000066 !important;
  }
}

@mixin light {
  $ocolz: $colw bg1, $colwb bg2, $colwd bg4, $colwd bg3, $colwb bgc, $colb4 csw,
    $cold3 csw2, $coltrans ctr, #fa5537 c0, $colmain cmain, $colb2 c1,
    $coll1 cl2, $coll3 cl3, $coll4 cl4, $cold2 cd2, $cold4 cd4, #c9cc3f ccg,
    #117b11 ccg2, #c9cc3f66 ccgb, $colpurple ccp, $colpurplel ccp2,
    $colpurple ccp3, $colblue ccb, $colorange cco, $colred ccr, $colpink cck,
    #f26fb6 cckb, $colyellow ccy, $colw cw, $colwe cwe, $colwd cwd, $colb0 b0,
    $colb2 b1, $colb3 b2, $colb3 b3, $colgoogle cgg, $colb4 b4, $colb9 b9,
    $linw linbg, $linb linb, $linb2 linb2, $ling ling, $lintrwl lintrl,
    $lintrwr lintrr, $lin1 lin1;
  @each $o in $ocolz {
    .#{nth($o, 2)},
    .#{nth($o, 2)}:hover,
    .#{nth($o, 2)}::placeholder {
      color: nth($o, 1) !important;
    }

    .bg-#{nth($o, 2)},
    .bg-#{nth($o, 2)}:focus {
      background: nth($o, 1) !important;
      background-color: nth($o, 1) !important;
      background-position: top left !important;
    }

    .bo-#{nth($o, 2)} {
      border: 1px solid nth($o, 1);
      border-color: nth($o, 1) !important;
    }

    .bo3-#{nth($o, 2)} {
      border: 0.2em solid nth($o, 1);
      border-color: nth($o, 1) !important;
    }

    .bot-#{nth($o, 2)} {
      border-top: 0.2em solid nth($o, 1) !important;
    }

    .bol-#{nth($o, 2)} {
      border-left: 0.2em solid nth($o, 1);
    }

    .bor-#{nth($o, 2)} {
      border-right: 0.2em solid nth($o, 1) !important;
    }

    .bob-#{nth($o, 2)} {
      border-bottom: 0.2em solid nth($o, 1) !important;
    }
  }

  h2 {
    background: $linw;
  }
  input,
  select,
  textarea,
  .legal {
    background: $colw !important;
  }
  .scro::-webkit-scrollbar-thumb,
  .bn-onboard-modal-content-header-icon {
    background-color: $cold4 !important;
  }

  .bn-onboard-modal-content-header-icon {
    color: $colw !important;
  }
  h2,
  input,
  select,
  textarea,
  .legal,
  .tabsy .nav-link {
    color: $cold4 !important;
  }
  h2 {
    border: 1px solid $cold2;
  }
  input::placeholder,
  textarea::placeholder,
  select::placeholder {
    color: $colmain !important;
  }

  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number,
  .cal-month-view .cal-day-cell.cal-today {
    color: $colb4;
  }

  input:focus,
  textarea:focus,
  select:focus,
  .tabsy .nav-link {
    background: $colw !important;
  }

  .box2,
  button {
    // box-shadow: 2px 2px 5px #00000033;
  }

  .fullpad {
    .mat-dialog-container {
      background-color: #eeeeee;
    }
  }

  .nav-item.nav-link.active,
  .progress-bar {
    background: $ling !important;
    color: $colb2 !important;
  }

  .cal-day-cell,
  .cal-cell-row,
  .cal-days {
    border-color: $colwd !important;
  }

  .cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
    background: $colpurple;
    color: $colw;
  }

  .vscro::-webkit-scrollbar-thumb,
  .hscro::-webkit-scrollbar-thumb {
    background-color: $cold2;
  }

  .totabs .nav-pills .nav-link {
    border-radius: 12px;
    color: $colb2;
    background-color: $colwe;
    padding: 20px 0 !important;
  }
  .totabs .nav-pills .nav-link.active {
    background: $colpink;
    color: $colw;
  }
}

// * G E N - C A L E N D A R
.cal-day-number {
  opacity: 1 !important;
}

.cal-month-view .cal-day-badge {
  background-color: $colw !important;
  color: $colpurple !important;
}
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  border: 1px solid $colpurple !important;
}

.cal-month-view .cal-open-day-events {
  background-color: $colpurple !important;
}
.cal-day-selected,
.cal-day-selected:hover {
  background-color: $colpink !important;
  color: $colw !important;
  .cal-day-number {
    color: $colw !important;
  }
}
.cal-day-selecte,
.cal-day-selecte:hover {
  background-color: $colred !important;
  color: $colw !important;
  .cal-day-number {
    color: $colw !important;
  }
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.3 !important;
  cursor: default;
}

.cal-month-view .cal-day-cell.cal-today {
  background: transparent !important;
}

//* B O R D E R S
$brads: 7px;
$brad: 12px;
$brad2: 30px;
$brad3: 50px;
$bradd: 50%;

.brads {
  border-radius: $brads !important;
}

.brad {
  border-radius: $brad !important;
}

.brad2 {
  border-radius: $brad2 !important;
}

.brad3 {
  border-radius: $brad3 !important;
}

.bradd {
  border-radius: $bradd !important;
}

//* FONT SIZES
.fs07 {
  font-size: 0.7em !important;
}
.fs09 {
  font-size: 0.9em !important;
}
.fs1 {
  font-size: 1em !important;
}
.fs12 {
  font-size: 1.2em !important;
}
.fs15 {
  font-size: 1.5em !important;
}
.fs2 {
  font-size: 2em !important;
}
.fs3 {
  font-size: 3em !important;
}
.fs4 {
  font-size: 4em !important;
}

//* WIDTHS
.wi05 {
  width: 50px !important;
}
.wi1 {
  width: 100px !important;
}
.wi15 {
  width: 170px !important;
}
.wi2 {
  width: 200px !important;
}
.wi25 {
  width: 250px !important;
}
.wi3 {
  width: 300px !important;
}
.wi4 {
  width: 400px !important;
}
.wim {
  width: 92vw;
  margin: 0 auto;
  max-width: 500px;
}
.wim3 {
  width: 92%;
  margin: 0 auto;
  max-width: 350px;
}
.wim5 {
  width: 92%;
  margin: 0 auto;
  max-width: 500px;
}
.wimf5 {
  width: 100vw;
  margin: 0 auto;
  max-width: 500px;
}
.wim6 {
  width: 92vw;
  margin: 0 auto;
  max-width: 600px;
}
.wim8 {
  width: 96vw;
  max-width: 800px;
}

.wi50 {
  width: 50%;
}

.max-1vw {
  max-width: 100vw;
}

.maw-5 {
  display: inline-block;
  overflow: hidden;
  max-width: 40%;
}

.trunc {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mih-2 {
  min-height: 80px;
}

.lih-1 {
  line-height: 2rem;
}

.li0 {
  line-height: 0 !important;
}

//* FONT WEIGHTS
@for $i from 1 through 9 {
  .fw-#{$i} {
    font-weight: #{$i}00 !important;
  }
}

// * LETTER SPACING
@for $i from 1 through 9 {
  .spa#{$i} {
    letter-spacing: #{$i}px !important;
  }
}

//* B O X :: S H A D O W S
$box1: 3px 3px 8px #ddd, -5px -5px 8px #fff !important;

$box2: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

$box3: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

$box4: none !important;

.box1 {
  box-shadow: $box1;
}
.box3 {
  box-shadow: $box3;
}
.box4 {
  box-shadow: $box4;
}

.txbox {
  text-shadow: 1px 1px 1px #99999944;
}

//* OPACITY

.transp {
  opacity: 0.6 !important;
}

.opa1 {
  opacity: 1 !important;
}
